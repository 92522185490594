import {React, Link} from 'react-router-dom'


const PageNotFound = () => {
  return (
    <>
    <section className='bg-black min-h-screen text-center text-white flex justify-center items-center flex-nowrap flex-row'>
            <div className='w-3/12'>
                <div className='w-[600px] mx-auto'>
                   {/* <lottie-player src='https://lottie.host/950a4670-b24f-4c58-ba4b-7dac8001ce75/wCtinrpnj5.json' background='transparent' speed='1' loop autoplay></lottie-player> */}
                   <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_euaveaxu.json"  background="transparent"  speed="1"  loop  autoplay></lottie-player>
                </div>
            </div>
           <div className='w-9/12'>
                <h1 className='text-[20vw] font-bold leading-[100%] mb-20 bg-clip-text bg-[url(assets/images/404.jpg)] bg-cover text-transparent'>Opps!</h1>
                <h2 className='text-2xl'>404 - Page Not Found</h2>
                <Link to='/' className='button-template mt-5 !bg-[#F6BA41] !hover:bg-[#292929] !w-[250px]'>Return Home</Link>
           </div>
       </section>
       </>
  )
}

export default PageNotFound