import React, { useRef }  from 'react'
import {BsPersonFill, BsFillEnvelopeFill, BsFillTelephoneFill, BsPencilFill} from "react-icons/bs"; 
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ContactUs = () => {
    const MySwal = withReactContent(Swal)
    const form = useRef();
  
    const sendEmail = (e) => { 
      e.preventDefault();
  
      emailjs.sendForm('service_0qai3wa', 'template_oqn688x', form.current, 'gToTeXkVwW2WifPN4')
        .then((result) => {
          MySwal.fire({
            title: 'Success!',  
            text: 'Successfuly sent message!',
            icon: 'success',
          })
        }, (error) => {
          MySwal.fire({
            title: 'Error!',
            text: 'Message not sent!',
            icon: 'error',
          })
        });
    };
  return (
    <>
        <section id='contact-us' className='min-h-screen flex items-center justify-center w-full px-5'>
            <form action="" className='w-[1000px] max-w-full' ref={form} onSubmit={sendEmail}>
                <div className='relative w-full'>
                    <BsPersonFill className='text-3xl absolute text-white mt-2 ml-1'/>
                    <input type="text" placeholder='Name' name='from_name' required className='w-full h-11 px-10 mb-3 text-2xl text-white bg-[rgba(0,0,0,.8)] leading-[100%] border-2 rounded-md'/>
                </div>
                <div className='relative w-full'>
                    <BsFillEnvelopeFill className='text-2xl absolute text-white mt-2 ml-1'/>
                    <input type="email" placeholder='Email Address' name='from_email' required className='w-full h-11 px-10 mb-3 text-2xl text-white bg-[rgba(0,0,0,.8)] leading-[100%] border-2 rounded-md'/>
                </div>
                <div className='relative w-full'>
                    <BsFillTelephoneFill className='text-2xl absolute text-white mt-2 ml-1'/>
                    <input type="text" placeholder='Phone Number' name='from_number' required className='w-full h-11 px-10 mb-3 text-2xl text-white bg-[rgba(0,0,0,.8)] leading-[100%] border-2 rounded-md'/>
                </div>
                <div className='relative w-full'>
                    <BsPencilFill className='text-2xl absolute text-white mt-2 ml-1'/>
                    <textarea name="from_message" placeholder='Write Message' required className='w-full h-40 px-10 mb-3 text-2xl text-white bg-[rgba(0,0,0,.8)] leading-[100%] border-2 rounded-md pt-2'></textarea>
                </div>
                <div className='g-recaptcha my-3 z-40 relative' data-sitekey='6LcRG3UjAAAAAPQ94TvRf_DSNRIqiFTtnYH0w2sS'></div>
                <input type="submit" name='submit' value='Send' className='w-full rounded-md text-white uppercase bg-black h-10 mx-auto max-w-full text-2xl text-center left-0 right-0 cursor-pointer hover:bg-white hover:text-black z-40 relative'/>
            </form>
        </section>
    </>
  )
}

export default ContactUs