import React from 'react'

const Home = () => {
  return (
    <section id='home' className='min-h-screen flex justify-center items-center px-5 bg-websiteBackground'>
            <h1 className='heading-template'>Chanmattee Lynnie Bachoo</h1>
    </section>
  )
}

export default Home