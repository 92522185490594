import React from 'react'
import SiteIcon from '../assets/images/site-icon.png'

const Copyright = () => {
  return (
    <>
        <div className='absolute bottom-5 left-0 right-0 w-full text-center text-white text-sm px-5 tablet:text-2xl'>
            <img src={SiteIcon} alt="site icon" className='w-5 inline-block'/> Copyright @{(new Date().getFullYear())} <a href="htpps://pageturner.us" target='_blank'  rel="noreferrer" className='hover:underline'>Pageturner Press and Media</a>. All rights reserved.
        </div>
    </>
  )
}

export default Copyright