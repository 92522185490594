import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from './routes/Home'
import AboutTheBook from './routes/AboutTheBook'
import AboutTheAuthor from './routes/AboutTheAuthor'
import BookOrder from './routes/BookOrder'
import ContactUs from './routes/ContactUs'
import PageNotFound from './routes/PageNotFound'
import Navigation from "./components/Navigation";
import NavBurger from "./components/NavBurger";
import Copyright from "./components/Copyright";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
  <>
   <Routes>
      <Route path="/" element={<Home />}> </Route>
      <Route path="/about-the-author" element={<AboutTheAuthor />}> </Route>
      <Route path="/about-the-book" element={<AboutTheBook />}> </Route>
      <Route path="/book-order" element={<BookOrder />}> </Route>
      <Route path="/contact-us" element={<ContactUs />}> </Route>
      <Route path="*" element={<PageNotFound />}> </Route>
   </Routes>

   <NavBurger/>
      {/* <Navigation/> */}

    

    <Copyright/>
  </>
  );
}

export default App;
