import {React, useState} from 'react'
import {Link} from 'react-router-dom'
import { Divide as Hamburger } from 'hamburger-react'

const NavBurger = () => {
    const [isActive, setIsActive] = useState(true);
    
    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
      };
  return (
    <>
    <div className='absolute top-0 right-0 z-[999999] text-white tablet:hidden block'>
     <Hamburger toggled={isActive} toggle={setIsActive}  onClick={handleClick}/>
    </div>
   
        <nav className={isActive ? 'navigation nav-active' : 'navigation nav-hidden'}>
            <ul className='tablet:flex items-center justify-center text-2xl font-primary block'>
                    <li className='mx-4'> <Link to="/" className='hover:underline' toggled={setIsActive}> Home </Link> </li>
                    <li className='mx-4'> <Link to="/about-the-book" className='hover:underline'> About the Books </Link> </li>
                    <li className='mx-4'>  <Link to="/about-the-author" className='hover:underline'> About the Author </Link> </li>
                   <li className='mx-4'> <Link to="/book-order" className='hover:underline'> Book Order </Link> </li>
                    <li className='mx-4'> <Link to="/contact-us" className='hover:underline'> Contact Us </Link> </li>
                </ul>
        </nav>
        
    </>
  )
}

export default NavBurger