import React from 'react'
import MrsGollDooneysAdventures from '../assets/images/mrs-goll-dooneys-adventures.png'
import TheGollDooneysGarden from '../assets/images/the-golldooneys-garden.jpg'
import Slider from "react-slick";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";


const AboutTheBook = () => {

    function SampleNextArrow(props) {
        const {  onClick } = props;
        return (
          <BsArrowRightSquareFill className='hover:text-black z-10 absolute top-[50%] translate-x-[-50%] right-0 cursor-pointer text-4xl text-white'  onClick={onClick}/>
        );
      }

      
    function SamplePrevArrow(props) {
        const {  onClick } = props;
        return (
            <BsArrowLeftSquareFill className='hover:text-black z-10 absolute top-[50%] translate-x-[-50%] left-0 cursor-pointer text-4xl text-white'  onClick={onClick}/>
        );
      }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

  return (
    <>
        <section id='about-the-book' className='px-5'>
            <div className='w-[1200px] mx-auto max-w-full'>
                <Slider {...settings}>
                    { <div>
                        <div className='w-full flex items-center justify-center flex-wrap tablet:flex-nowrap min-h-screen flex-col tablet:flex-row'>
                            <img src={MrsGollDooneysAdventures} alt="mrs goll dooneys adventures" loading='lazy' className='tablet:w-[300px] w-[200px] mx-w-full mr-10 mb-4'/>
                            <p className='text-white text-2xl w-full tablet:w-[60%]'>Mrs. Ava Golldooney encounters a parrot and the monkey with her hat, along with a curious master bumble bee and six of their friends, who go paragliding from a valley mountain.</p>
                        </div>
                    </div> }
                    <div>
                        <div className='w-full flex items-center justify-center flex-wrap tablet:flex-nowrap min-h-screen flex-col tablet:flex-row'>
                            <img src={TheGollDooneysGarden} alt="The Goll Dooneys Garden" loading='lazy' className='tablet:w-[300px] w-[200px] mx-w-full mr-10  mb-4'/>
                            <p className='text-white text-2xl w-full tablet:w-[60%]'>I love this book very much. Farmer Golldooney is encouraged and complemented by the sweet singing of the birds and Mr. Bluster Dog his pet, and winning a trophy. </p>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    </>
  )
}

export default AboutTheBook