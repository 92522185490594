import React from 'react'
import MrsGollDooneysAdventures from '../assets/images/mrs-goll-dooneys-adventures.png'
import TheGollDooneysGarden from '../assets/images/the-golldooneys-garden.jpg'
import Slider from "react-slick";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const BookOrder = () => {

    function SampleNextArrow(props) {
        const {  onClick } = props;
        return (
          <BsArrowRightSquareFill className='hover:text-black z-10 absolute top-[50%] translate-x-[-50%] right-0 cursor-pointer text-4xl text-white'  onClick={onClick}/>
        );
      }

      
    function SamplePrevArrow(props) {
        const {  onClick } = props;
        return (
            <BsArrowLeftSquareFill className='hover:text-black z-10 absolute top-[50%] translate-x-[-50%] left-0 cursor-pointer text-4xl text-white'  onClick={onClick}/>
        );
      }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

  return (
    <>
        <section id='about-the-book' className='px-5'>
            <div className='w-[1200px] mx-auto max-w-full'>
                <Slider {...settings}>
                    { <div >
                        <div className='w-full flex items-center justify-center min-h-screen tablet:flex-nowrap flex-wrap tablet:flex-row flex-col'>
                            <img src={MrsGollDooneysAdventures} alt="mrs goll dooneys adventures" loading='lazy' className='w-[200px] tablet:w-[300px] mx-w-full  mr-10 mb-5'/>
                            <Link to="https://pageturner.us/bookstore/mrs-ava-golldooneys-adventures-a-trapped-master-bumble-bee-and-friends-go-gliding-from-a-valley-mountain" className='text-white uppercase text-5xl tracking-[4px] underline drop-shadow-lg shadow-black hover:no-underline' target='_blank'>Order Now</Link>
                        </div>
                    </div> }
                    <div>
                        <div className='w-full flex items-center justify-center min-h-screen tablet:flex-nowrap flex-wrap tablet:flex-row flex-col'>
                            <img src={TheGollDooneysGarden} alt="The Goll Dooneys Garden" loading='lazy' className='w-[200px] tablet:w-[300px] mx-w-full mr-10 mb-5'/>
                            <Link  to="/contact-us" className='text-white uppercase text-5xl tracking-[4px] underline drop-shadow-lg shadow-black hover:no-underline'>Order Now</Link>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    </>
  )
}

export default BookOrder