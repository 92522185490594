import React from 'react'
import AuthorImage from '../assets/images/author-image.png'

const AboutTheAuthor = () => {
  return (
    <>
        <section id='about-the-author' className='flex justify-center items-center min-h-screen px-5 flex-wrap tablet:flex-nowrap flex-col tablet:flex-row'>
            <img src={AuthorImage} alt="author" loading='lazy' className='w-[300px] max-w-full laptop:w-[500px]' />

            <div>
                <h1 className='heading-template'>Chanmattee Lynnie Bachoo</h1>
                <p className='text-white text-2xl w-[60%] mx-auto leading-6'>Loves to write anywhere in my spare time and create magical moments in my stories.</p>
            </div>
        </section>
    </>
  )
}

export default AboutTheAuthor